<template>
  <div class="flex flex-col relative w-full overflow-hidden">
    <div class="absolute right-0 mt-4 mr-4 z-10">
      <ButtonIcon @click="toggleView">
        <ViewListIcon class="w-8 text-blue" />
      </ButtonIcon>
    </div>
    <div class="inset-0 flex-grow">
      <VMap :is-mobile="true" />
    </div>
    <template v-if="sheetOpen">
      <BottomSheet @closed="closeSheet">
        <component
          :is="sheetComponent"
          :site="selectedSite"
        />
      </BottomSheet>
    </template>
  </div>
</template>

<script>
import { ref, onMounted, computed, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { ViewListIcon } from '@heroicons/vue/outline';
import { emitter } from '@/common/utils/emitter';
import VMap from '@/components/organisms/VMap.vue';
import ButtonIcon from '@/components/atoms/ButtonIcon.vue';
import BottomSheet from '@/components/molecules/BottomSheet.vue';
import InfoCard from '@/components/LocatorMobile/organisms/InfoCard.vue';
import SiteDetails from '@/components/molecules/SiteDetails.vue';
import { useGeocoding } from '@/common/composition/map';

export default {
  name: 'SiteMap',
  components: {
    ButtonIcon,
    VMap,
    ViewListIcon,
    BottomSheet,
    InfoCard,
    SiteDetails, 
  },
  setup(props, { emit }) {
    const { setState } = useGeocoding(); // binds listeners using composition api
    const store = useStore();
    const selectedSite = ref({});
    const sheetOpen = ref(false);
    const sheetComponent = ref('SiteDetails');

    const toggleView = () => emit('toggle:view');

    const componentProps = computed(() => (sheetComponent.value === 'SiteDetails' ? { site: selectedSite } : { site: {} }));

    const openSheet = () => sheetOpen.value = true;
    const closeSheet = () => sheetOpen.value = false;

    onMounted(() => {
      emitter.on('clicked:marker', (payload) => {
        selectedSite.value = store.getters.GET_SITE_BY_ID(payload);
        sheetComponent.value = 'SiteDetails';
        openSheet();
      });
      emitter.on('clicked:info', () => {
        sheetComponent.value = 'InfoCard';
        openSheet();
      });
    });

    onBeforeUnmount(() => {
      emitter.off('clicked:marker');
      emitter.off('clicked:info');
    });

    return {
      sheetOpen,
      closeSheet,
      toggleView,
      selectedSite,
      sheetComponent,
      componentProps,
    };
  },
};
</script>
