<template>
  <div class="flex flex-col">
    <TabMenu
      v-model="tabValue"
      :menu="menuItems"
    />
    <keep-alive>
      <component
        :is="currentComponent"
        class="flex-grow pt-6 transition transition-height duration-500 ease-in-out"
      />
    </keep-alive>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import TabMenu from '@/components/molecules/TabMenu.vue';
import InfoLegend from '@/components/LocatorMobile/molecules/InfoLegend.vue';
import InfoContact from '@/components/LocatorMobile/molecules/InfoContact.vue';
import InfoProgram from '@/components/LocatorMobile/molecules/InfoProgram.vue';

const legendItem = 'legend';
const programItem = 'program';
const contactItem = 'contact';

export default {
  components: {
    TabMenu,
    InfoLegend,
    InfoContact,
    InfoProgram,

  },
  setup() {
    const menuItems = [
      {
        id: legendItem,
        title: 'Legend',
      },
      {
        id: programItem,
        title: 'Program Info',
      },
      {
        id: contactItem,
        title: 'Contact',
      },
    ];

    const tabValue = ref('legend');

    const currentComponent = computed(() => {
      const v = tabValue.value; 
      if (v === legendItem){
        return InfoLegend;
      }
      if (v === programItem ){
        return InfoProgram;
      }
      if (v === contactItem ){
        return InfoContact;
      }
      return InfoLegend;
    });

    return { menuItems, tabValue, currentComponent };
  },
};
</script>
