<template>
  <ul class="flex flex-wrap list-none p-0 m-0">
    <li v-for="item in menu" :key="item.id" @click="changeSection(item.id)" class="cursor-pointer p-2">
      <div class="block">
        <span class="text-16 font-bold" :class="active(item.id)">{{
          item.title
        }}</span>

        <template v-if="item.id === section">
          <div class="w-full bg-blue mt-1" style="height: 2px;"></div>
        </template>
      </div>
    </li>
  </ul>
</template>

<script>
import { ref, toRef } from 'vue';

export default {
  props: {
    menu: {
      type: Array,
      required: true,
      default: () => [],
    },
    modelValue: {
      type: String,
      required: true,
      default: () => '',
    },
  },
  setup(props, { emit }) {

    const activeValue = toRef(props, 'modelValue').value;

    const section = ref(activeValue);

    const active = (value) => {
      if (value === section.value) {
        return 'text-blue';
      }
      return 'text-grey';
    };

    const changeSection = (value) => {
      section.value = value;

      emit('update:modelValue', value);
    };

    return { section, active, changeSection };
  },
};
</script>
