<template>
  <div
    id="bottomSheet"
    :style="{ bottom: `${position}px` }"
    class="
      absolute
      bottom-0
      inset-x-0
      transition
      transition-all
      w-98/100
      z-50
      bottom-sheet__container
      max-h-2/3
      overflow-y-scroll
    "
    :class="{ 'transition-none': moving }"
  >
    <div
      id="bottomSheetContent"
      class="
        relative
        shadow-upper
        w-full
        bg-white
        rounded-t
        p-8
        "
    >
      <div id="closeIcon">
        <XIcon class="h-8 w-8 font-black absolute top-8 right-8 z-20" />
      </div>
      <handle />
      <div class="flex flex-col items-start mt-6">
        <div class="mb-16 w-full">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import * as Hammer from '@/assets/js/hammer';
import Handle from '@/components/atoms/handle.vue';
import { XIcon } from '@heroicons/vue/outline';

const APPLE_ASPECT = '0.462';
const OFF_SCREEN_POSITION = -30;

export default {
  components: {
    Handle,
    XIcon,
  },
  emits: ['closed'],
  setup(props, { emit }) {
    const position = ref(0);
    const moving = ref(false);
    const stripe = ref(0);
    const contentScroll = ref(0);

    const bottomSheet = ref(null);
    const bottomSheetContent = ref(null);
    const closeIcon = ref(null);

    const iPhone = () => {
      const apple = /iPhone/.test(navigator.userAgent) && !window.MSStream;
      const aspect = window.screen.width / window.screen.height;
      return apple && aspect.toFixed(3) === APPLE_ASPECT;
    };

    const move = (event, type) => {
      const delta = -event.deltaY;
      const height = bottomSheet.value.clientHeight;
      let top = bottomSheetContent.value.scrollTop;

      if (event.type === 'tap' && type === 'icon') {
        position.value = -height - stripe.value;
        document.body.style.overflow = '';
        emit('closed');
      }

      if (
        (type === 'content' && event.type === 'panup') ||
        (type === 'content' &&
          event.type === 'pandown' &&
          contentScroll.value > 0)
      ) {
        top = contentScroll.value + delta;
      } else if (event.type === 'panup' || event.type === 'pandown') {
        moving.value = true;
        if (event.deltaY > 0) {
          position.value = delta;
        }
      }
      if (event.isFinal) {
        contentScroll.value = top;
        moving.value = false;
        if (position.value < OFF_SCREEN_POSITION) {
          position.value = -height - stripe.value;
          document.body.style.overflow = '';
          emit('closed');
        } else {
          position.value = 0;
        }
      }
    };

    const hammerTime = () => {
      const options = {
        recognizers: [
          [Hammer.Pan, { direction: Hammer.DIRECTION_VERTICAL }],
          [Hammer.Tap],
        ],
      };

      bottomSheet.value = document.getElementById('bottomSheet');
      bottomSheetContent.value = document.getElementById('bottomSheetContent');
      closeIcon.value = document.getElementById('closeIcon');

      if (!bottomSheet.value || !bottomSheetContent.value || !closeIcon.value) {
        return;
      }

      const hSheet = new Hammer(bottomSheet.value, options);
      const cSheet = new Hammer(bottomSheetContent.value, options);
      const icon = new Hammer(closeIcon.value, options);

      const iPhoneAspectBottom = 20;

      stripe.value = iPhone() ? iPhoneAspectBottom : 0;

      hSheet.on('panstart panup pandown panend', (event) => move(event, 'pan'));

      cSheet.on('panstart panup pandown panend', (event) =>
        move(event, 'content'),
      );

      icon.on('tap', (event) => move(event, 'icon'));
    };

    onMounted(hammerTime);

    return { position, moving };
  },
};
</script>

<style scoped>
.bottom-sheet__container {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

::-webkit-scrollbar {
  display: none;
}

</style>
