<template>
  <div class="text-left"> 
   <p class="text-12 text-black " v-html="program.info"></p>
   <p class="text-12 text-black " v-html="program.disclaimer"></p>
  </div>
</template>

<script>
import { text } from '@/common/config/sites.config';

export default {
  name: "ProgramInfo",
  computed: {
    program() {
      return text.program;
    },
  },
};
</script>
