<template>
  <p class="text-left text-12 text-black " v-html="contact"></p>
</template>

<script>
import { text } from '@/common/config/sites.config';

export default {
  name: "InfoContact",
  computed: {
    contact() {
      return text.contact.info;
    },
  },
};
</script>
