<template>
  <div>
    <div class="flex mt-2" v-for="(item, i) in legendData" :key="i">
      <div class="h-4 w-4 mr-6 my-4">
        <img :src="item.icon" />
      </div>
      <sp class="w-full text-left pt-2">{{ item.title }}</sp>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

import YellowBlack from '@/assets/icons/yellow-black.svg';
import BlueBlack from '@/assets/icons/default-l-00b7ff.svg';
import GreenBlack from '@/assets/icons/default-l-8dc114.svg';
import OrangeBlack from '@/assets/icons/default-l-ff9300.svg';
import BlueStar from '@/assets/icons/star-l-00b7ff.svg';
import GreenStar from '@/assets/icons/star-l-8dc114.svg';
import YellowStar from '@/assets/icons/star-l-ffce00.svg';
import OrangeStar from '@/assets/icons/star-l-ff9300.svg';
import sp from '@/components/atoms/s-p-small.vue';

export default {
  name: 'InfoLegend',
  components: {
    sp,
  },
  setup() {
    const legendData = ref([
      {
        icon: BlueBlack,
        title: 'Accepts other household hazardous waste',
      },
      {
        icon: GreenBlack,
        title: 'Accepts up to 5 gal',
      },
      {
        icon: YellowBlack,
        title: 'Accepts up to 10 or 20 gal',
      },
      {
        icon: OrangeBlack,
        title: 'Accepts up to 100 gal',
      },
      {
        icon: BlueStar,
        title: 'Accepts other household hazardous waste and has reuse program',
      },
      {
        icon: GreenStar,
        title: 'Accepts up to 5 gal and has reuse program',
      },
      {
        icon: YellowStar,
        title: 'Accepts up to 10 or 20 gal and has reuse program',
      },
      {
        icon: OrangeStar,
        title: 'Accepts up to 100 gal and has reuse program',
      },
    ]);

    return {
      legendData,
    };
  },
};
</script>
